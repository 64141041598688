<template>
  <div style="text-align: center">
    <div>
      <div>
        <button class="btn c2play-primary" @click="downloadQRCode">
        <span style="color: #fff"
          ><i class="mdi mdi-download font-size-18"></i
        ></span>
      </button>
      </div>
      <canvas ref="qrcodeCanvas"></canvas>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcode";

export default {
  data() {
    return {
      value: "https://2play.cm/listquestion/",
      size: 200,
    };
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    fileName: {
      type: String,
      required: true,
    },
  },
  methods: {
    generateQRCode() {
      const qrcodeCanvas = this.$refs.qrcodeCanvas;

      // Génère le QR code sur le canvas
      QRCode.toCanvas(qrcodeCanvas, this.url, {
        width: this.size,
        height: this.size,
        margintop: 0,
      });
    },
    downloadQRCode() {
      const qrcodeCanvas = this.$refs.qrcodeCanvas;

      // Convertit le contenu du canvas en une URL de données (format image/png)
      const qrCodeDataURL = qrcodeCanvas.toDataURL("image/png");

      // Crée un lien de téléchargement avec l'URL du QR code
      const downloadLink = document.createElement("a");
      downloadLink.href = qrCodeDataURL;
      downloadLink.download = this.fileName + ".png";

      // Simule le clic sur le lien de téléchargement
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
  },
  mounted() {
    this.generateQRCode();
  },
};
</script>

<style scoped>
/* Ajoutez du style CSS personnalisé ici si nécessaire */
</style>


