<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { Api } from "../../../helpers";
import Swal from "sweetalert2";
import { Erreur } from "../../../helpers/error";
import loaderProcessVue from "../../../components/widgets/loaderProcess.vue";
import qrCodeGenerator from "../../../components/widgets/qrCodeGenerator.vue";
import questionQuizCard from "../../../components/widgets/card/questionQuizCard.vue";
import Switches from "vue-switches";
import Stat from "@/components/widgets/stat";
export default {
  props: {},
  page: {
    title: "Blog List",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    loaderProcessVue,
    Switches,
    qrCodeGenerator,
    questionQuizCard,
    Stat,
  },
  data() {
    return {
      titre: "",
      value: true,
      progress: 0,
      process: false,
      spinner: false,
      allQuestionQuiz: [],
      isAuthError: false,
      authError: "",
      oneQuiz: {
        image: "",
        name: "",
        startDate: "",
        endDate: "",
        isFree: false,
        isMultiAttent: false,
        isMultiChoice: false,
        price: 0,
        impressions: 0,
      },
      time:"",
      participation: [],
      questionQuiz: {
        name: "",
        indication: "",
        isMultiChoice: false,
      },
      image: "",
      addModal: false,
      secondModal: false,
      title: "Liste des compétitions",
      items: [],
    };
  },
  methods: {
    creerMinuteur(dateDebut, dateFin) {
      const differenceEnMillisecondes = dateFin - dateDebut;
      const differenceEnSecondes = Math.floor(differenceEnMillisecondes / 1000);

      let tempsRestant = differenceEnSecondes;
      let minuteur;

      const afficherTempsRestant = temps => {
        const jours = Math.floor(temps / (3600 * 24));
        temps %= 3600 * 24;
        const heures = Math.floor(temps / 3600);
        temps %= 3600;
        const minutes = Math.floor(temps / 60);
        const secondes = temps % 60;

        const tempsRestantFormat =
          jours + "j " + heures + "h " + minutes + "m " + secondes + "s";

        // console.log(`Temps restant : ${tempsRestantFormat}`);
        this.time = tempsRestantFormat;
      };

      const demarrerMinuteur = () => {
        minuteur = setInterval(() => {
          if (tempsRestant > 0) {
            tempsRestant--;
            afficherTempsRestant(tempsRestant);
          } else {
            clearInterval(minuteur);
            this.time = "00j 00h 00m 00s"
            console.log("Minuteur terminé !");
          }
        }, 1000); // Interval d'une seconde (1000 ms)
      };

      // Pour démarrer le minuteur
      demarrerMinuteur();
    },
    showAddModal() {
      this.addModal = true;
    },
    imgVideoSelect(event) {
      this.imgSelect = event.target.files[0]; // Get the selected image or video
      console.log(this.imgSelect);
    },

    imgVideoSelectPaysage(event) {
      this.imgPaysage = event.target.files[0];
      console.log(this.imgPaysage);
    },

    imgSponsor(event) {
      this.sponsorLogo = event.target.files[0];
      console.log(this.sponsorLogo);
    },
    confirmPublishResult() {
      Swal.fire({
        title:
          this.oneQuiz.resultPublished == false
            ? "Souhaitez-vous publier les résultats de ce quiz ?"
            : "Souhaitez-vous dépublier les résultats de ce quiz ?",
        // text: "You won't be able to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        cancelButtonText: "Annuler",
        confirmButtonText: "Oui",
      }).then((result) => {
        if (result.value) {
          this.publishResult();
        }
      });
    },
    publishResult() {
      Api.put(`/games/rest/quizz/publish-result/${this.$route.params.id}`)
        .then(function (response) {
          Swal.fire("Success!", "Résultats publiés", "success");
          console.log(response);
          location.reload();
        })
        .catch((error) => {
          this.process = false;
          this.progress = 0;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },

    confirmPublishQuiz() {
      Swal.fire({
        title:
          this.oneQuiz.published == false
            ? "Souhaitez-vous publier ce quiz ?"
            : "Souhaitez-vous dépublier ce quiz ?",
        // text: "You won't be able to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        cancelButtonText: "Annuler",
        confirmButtonText: "Oui",
      }).then((result) => {
        if (result.value) {
          this.publishQuiz();
        }
      });
    },
    publishQuiz() {
      Api.put(`/games/rest/quizz/publish-quizz/${this.$route.params.id}`)
        .then(function (response) {
          Swal.fire("Success!", "Quiz publié", "success");
          console.log(response);
          location.reload();
        })
        .catch((error) => {
          this.process = false;
          this.progress = 0;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },
    createAward() {
      const onUploadProgress = (progressEvent) => {
        this.progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        console.log(`Upload progress: ${this.progress}%`);
      };

      if (!this.questionQuiz.name) {
        this.isAuthError = true;
        this.authError = "Veuillez remplir tous les champs";
      } else {
        this.process = true;
        this.addModal = false;
        this.titre = "Ajout de la question";
        Api.post(
          `/games/rest/question/create/${this.$route.params.id}`,
          {
            content: this.questionQuiz.name,
            indication: this.questionQuiz.indication,
            isMultiChoice: this.questionQuiz.isMultiChoice,
          },
          { onUploadProgress }
        )
          .then((response) => {
            this.process = false;
            Swal.fire("Succes!", "Question ajoutée avec succès", "success");
            console.log(response);
            location.reload();
          })
          .catch((error) => {
            this.process = false;
            console.log(error);
            Erreur.gestionErreur(error.message);
          });
      }
    },
  },

  mounted() {

    // this.process = true
    this.titre = "Chargement des compétitions";
    // this.oneQuiz = JSON.parse(localStorage.getItem("quiz"));
    this.spinner = true;
    Api.get(`/games/rest/quizz/${this.$route.params.id}`)
      .then((res) => {
        this.process = false;
        this.oneQuiz = res.data;
        this.image = this.oneQuiz.urlImagePaysage;
        this.items.push(
          {
            text: "Quiz",
            href: "/games/categorieQuiz",
          },
          {
            text: this.oneQuiz.category.name,
            href: `/games/addQuiz/${this.oneQuiz.category.id}`,
          },
          {
            text: this.oneQuiz.name,
            active: true,
          }
        );
        
    const dateDebut = new Date();
    const dateFin = new Date(this.oneQuiz.endDate);
    this.creerMinuteur(dateDebut, dateFin);
        console.log(res);
      })
      .catch((error) => {
        this.process = false;
        console.log(error);
        Erreur.gestionErreur(error.message);
      });

    Api.get(`/games/rest/question/${this.$route.params.id}/quizz`)
      .then((res) => {
        this.process = false;
        this.allQuestionQuiz = res.data;
        this.spinner = false;
        console.log(res);
      })
      .catch((error) => {
        this.process = false;
        this.spinner = false;
        console.log(error);
        Erreur.gestionErreur(error.message);
      });

    Api.get(`/games/rest/participation/all-users/${this.$route.params.id}`)
      .then((res) => {
        console.log("res");
        console.log(res);
        this.participation = res.data;
      })
      .catch((error) => {
        this.process = false;
        this.spinner = false;
        console.log(error);
        Erreur.gestionErreur(error.message);
      });
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <loaderProcessVue
      :title="titre"
      :progress="progress"
      :visible="process"
    ></loaderProcessVue>
    <div>
        <h2>{{ time }}</h2>
      </div>
    <div class="card" style="padding: 20px; border-radius: 7px">
      <div class="row">
        <div class="col-xl-5">
          <div class="product-detai-imgs" style="">
            <img
              :src="image"
              alt
              style="
                object-fit: cover;
                height: 320px;
                width: 100%;
                border-radius: 10px;
              "
            />
          </div>
        </div>

        <div class="col-xl-7 col-sm-12 border-1">
          <div class="mt-1 card p-2 shadow-none border">
            <div style="text-align: justify">
              <div
                class="mb-2"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                "
              >
                <div>
                  <h2>{{ oneQuiz.name }}</h2>
                </div>
                <div>
                  <div>
                    <button
                      type="submit"
                      title="Publier les résultats"
                      class="btn border m-1"
                      @click="confirmPublishResult()"
                    >
                      <span style="color: #07693a"
                        ><i
                          class="mdi mdi-text-box-check-outline font-size-18"
                        ></i
                      ></span>
                    </button>
                    <button
                      type="submit"
                      title="Publier le quiz"
                      class="btn border m-1"
                      @click="confirmPublishQuiz()"
                      v-if="oneQuiz.published == true"
                    >
                      <span style="color: #07693a"
                        ><i class="mdi mdi-eye font-size-18"></i
                      ></span>
                    </button>
                    <button
                      type="submit"
                      title="Publier le quiz"
                      class="btn border m-1"
                      @click="confirmPublishQuiz()"
                      v-else
                    >
                      <span style="color: #07693a"
                        ><i class="mdi mdi-eye-off font-size-18"></i
                      ></span>
                    </button>
                  </div>
                </div>
              </div>
              <div v-if="oneQuiz.price > 0">
                <span>{{ oneQuiz.price }} XAF</span>
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin-top: 10px;
                "
              >
                <div>Taux de validation</div>
                <div>
                  <strong>{{ oneQuiz.quota }} %</strong>
                </div>
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin-top: 10px;
                "
              >
                <div>Le quiz est-il payant ?</div>
                <div>
                  <switches :value="oneQuiz.isFree" color="success"></switches>
                </div>
              </div>

              <!-- <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin-top: 10px;
                "
              >
                <div> Peut-on effectuer des choix multiple ? </div>
                <div>
                  <switches
                    :value="oneQuiz.isMultiChoice"
                    color="success"
                  ></switches>
                </div>
              </div> -->

              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin-top: 10px;
                "
              >
                <div>Peut-on effectuer plusieurs tentatives ?</div>
                <div>
                  <switches
                    :value="oneQuiz.isMultiAttent"
                    color="success"
                  ></switches>
                </div>
              </div>
              <!-- <div>{{ awardDetails.description }}</div> -->
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6">
              <!-- <statAward
              :route="`/award/transactionInAward/${this.$route.params.id}/${award.name}`"
              class="btn"
              :title="'Transactions'"
              :icon="'mdi mdi-compare-horizontal'"
              :value="transactionCount"
            >
            </statAward>
            <statAward
              :title="'Montant (XAF)'"
              :icon="'mdi mdi-cash-100'"
              :value="amountPayment"
            ></statAward> -->
              <router-link
                :to="`/games/usersParticipationInQuiz/${this.$route.params.id}`"
              >
                <Stat
                  :title="'Participations'"
                  :value="participation.length"
                  :className="'col-lg-12'"
                  :icon="'mdi mdi-account-circle'"
                />
              </router-link>
              <Stat
                  :title="'Impressions'"
                  :value="oneQuiz.impressions"
                  :className="'col-lg-12'"
                  :icon="'mdi mdi-monitor'"
                />
            </div>
            <div class="col-lg-6">
              <qrCodeGenerator :url="`https://2play.cm/quiz/${this.$route.params.id}`" :fileName="oneQuiz.name"></qrCodeGenerator>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-lg-10" style="margin-top: 10px">
        <div
          @click="showAddModal"
          class="btn c2play-primary"
          style="position: relative; left: 79%"
          to="/blog/createArticle"
        >
          <span style="color: #fff"
            ><i class="fas fa-plus font-size-18"></i> {{ $t("addButton") }} une
            question</span
          >
        </div>
      </div>
    </div>
    <br />

    <div class="row">
      <questionQuizCard
        v-for="question in allQuestionQuiz.slice().reverse()"
        :key="question.id"
        :question="question"
      ></questionQuizCard>
      <div style="text-align: center; font-size: 3em" v-if="spinner == true">
        <b-spinner variant="success" role="status"></b-spinner>
        <span v-if="spinner == false && allQuestionQuiz.length < 1"
          >Aucune question ajoutée dans cette catégorie</span
        >
      </div>
    </div>

    <b-modal
      v-model="addModal"
      id="modal-xl"
      size="xl"
      title="Nouvelle question"
      title-class="font-18"
      hide-footer
    >
      <!-- <div class="card" style="padding: 25px; width: 70%; margin-left: auto; margin-right: auto; border-radius: 15px;"> -->

      <div class="card-body">
        <b-alert
          v-model="isAuthError"
          variant="danger"
          class="mt-1"
          dismissible
        >
          {{ authError }}
        </b-alert>

        <form>
          <div class="form-group row mb-2">
            <div class="col-lg-12">
              <label for="projectname" class="col-form-label">{{
                $t("pages.videos.formulaire.titre.label")
              }}</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <input
                id="projectname"
                name="projectname"
                v-model="questionQuiz.name"
                type="text"
                class="form-control"
                placeholder="Entrez lz titre de la question"
              />
            </div>

            <div class="form-group col-lg-12 mb-3">
              <label class="col-form-label">Indication</label>
              <div class="col-lg-12">
                <input
                  id="projectname"
                  v-model="questionQuiz.indication"
                  name="projectname"
                  type="text"
                  class="form-control"
                  placeholder="Entrez l'indication de la question"
                />
              </div>
            </div>
          </div>

          <div class="form-group col-lg-12">
            <label for="projectname" class="col-form-label"
              >Peut-on effectuer des choix multiple ?</label
            ><span style="color: red; font-size: 1.2em">*</span>
            <div class="col-lg-12">
              <div class="display">
                <div style="margin-right: 20px">
                  <b-form-radio
                    v-model="questionQuiz.isMultiChoice"
                    name="some-radios2"
                    value="true"
                    >Oui</b-form-radio
                  >
                </div>
                <div>
                  <b-form-radio
                    v-model="questionQuiz.isMultiChoice"
                    name="some-radios2"
                    value="false"
                    >Non</b-form-radio
                  >
                </div>
              </div>
            </div>
          </div>
        </form>

        <div class="row">
          <div class="col-lg-12" style="margin-top: 10px">
            <button
              class="btn c2play-primary"
              @click="createAward"
              style="position: relative; left: 45%"
            >
              Créer
            </button>
          </div>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>
