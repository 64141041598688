<template>
  <div>
    <!-- Simple card -->
    <div class="col-lg-12 row card p-4">
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <div style="font-size: 1.2em">
          <router-link
            :to="`/games/addAnswerQuestion/${this.question.id}`"
            style="color: #303030"
            @click="saveQuestion()"
          >
            <div>{{ question.content }} <span style="margin-top: 10px; display: block;">
                  <switches
                    :value="question.isMultiChoice"
                    color="success"
                  ></switches>
                </span></div>
            <div style="font-size:0.7em; margin:5px 10px">{{ question.indication }}</div>
          </router-link>
        </div>
        <div>
          <b-button class="c2play-primary">
            <b-dropdown
              class="card-drop"
              variant="white"
              menu-class="dropdown-menu-end"
              right
              toggle-class="p-0"
            >
              <template v-slot:button-content>
                <span style="color: #fff"
                  ><i class="mdi mdi-dots-horizontal font-size-18"></i
                ></span>
              </template>
              <b-dropdown-item @click="showUpdateCat()">{{
                $t("pages.videos.actions.modifier")
              }}</b-dropdown-item>
              <b-dropdown-item @click="confirm()">{{
                $t("pages.videos.actions.supprimer")
              }}</b-dropdown-item>
            </b-dropdown>
          </b-button>
        </div>
      </div>
    </div>

    <b-modal
      v-model="modalUpdateQuestion"
      id="modal-xl"
      size="xl"
      title="Modifier la question"
      title-class="font-18"
      hide-footer
    >
      <!-- <div class="card" style="padding: 25px; width: 70%; margin-left: auto; margin-right: auto; border-radius: 15px;"> -->

      <div class="card-body">
        <b-alert
          v-model="isAuthError"
          variant="danger"
          class="mt-1"
          dismissible
        >
          {{ authError }}
        </b-alert>

        <form>
          <div class="form-group row mb-2">
            <div class="col-lg-12">
              <label for="projectname" class="col-form-label">{{
                $t("pages.videos.formulaire.titre.label")
              }}</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <input
                id="projectname"
                name="projectname"
                v-model="questionQuiz.name"
                type="text"
                class="form-control"
                :placeholder="$t('pages.videos.formulaire.titre.placeholder')"
              />
            </div>
            <div class="form-group col-lg-12 mb-3">
              <label class="col-form-label">Indication</label>
              <div class="col-lg-12">
                <input
                  id="projectname"
                  v-model="questionQuiz.indication"
                  name="projectname"
                  type="text"
                  class="form-control"
                  placeholder="Entrez le prix du vote"
                />
              </div>
            </div>
          </div>
          <div class="form-group col-lg-12">
              <label for="projectname" class="col-form-label"
                >Peut-on effectuer des choix multiple ?</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <div class="col-lg-12">
                <div class="display">
                  <div style="margin-right: 20px">
                    <b-form-radio
                      v-model="questionQuiz.isMultiChoice"
                      name="some-radios2"
                      value="true"
                      >Oui</b-form-radio
                    >
                  </div>
                  <div>
                    <b-form-radio
                      v-model="questionQuiz.isMultiChoice"
                      name="some-radios2"
                      value="false"
                      >Non</b-form-radio
                    >
                  </div>
                </div>
              </div>
            </div>
        </form>

        <div class="row">
          <div class="col-lg-12" style="margin-top: 10px">
            <button
              class="btn c2play-primary"
              @click="updateQuestion"
              style="position: relative; left: 45%"
            >
              Modifier
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <loaderProcessVue
      :title="titre"
      :progress="progress"
      :visible="process"
    ></loaderProcessVue>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { Api } from "../../../helpers";
import { Erreur } from "../../../helpers/error";
import loaderProcessVue from "../../../components/widgets/loaderProcess.vue";
import Switches from "vue-switches";
export default {
  components: {
    loaderProcessVue,
    Switches,
  },
  data() {
    return {
      titre: "",
      progress: 0,
      process: false,
      questionQuiz: {
				name: "",
				indication: "",
        isMultiChoice:false,
			},
      modalUpdateQuestion: false,
    };
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  methods: {
    saveQuestion(){
      localStorage.setItem("question", JSON.stringify(this.question))
    },
    showUpdateCat() {
      this.modalUpdateQuestion = true;
    },

    imgVideoSelect(event) {
      this.imgSelect = event.target.files[0]; // Get the selected image or video
      console.log(this.imgSelect);
    },

    imgVideoSelectPaysage(event) {
      this.imgPaysage = event.target.files[0];
      console.log(this.imgPaysage);
    },

    updateQuestion() {

      const onUploadProgress = (progressEvent) => {
        this.progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        console.log(`Upload progress: ${this.progress}%`);
      };

      this.process = true;
      this.modalUpdateQuestion = false;
      this.titre = "Modification de la question";
      Api.put(
        `/games/rest/question/update/${this.question.id}`,
        {
          content: this.questionQuiz.name,
					indication:this.questionQuiz.indication,
          isMultiChoice: this.questionQuiz.isMultiChoice,
        },
        {onUploadProgress}
      )
        .then((response) => {
          this.process = false;
          Swal.fire("Succes!", "La question a été modifié avec succès", "success");
          console.log(response);
          // location.reload();
        })
        .catch((error) => {
          this.process = false;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },
    confirm() {
      Swal.fire({
        title: "Voulez-vous vraiment supprimer cette question ?",
        // text: "You won't be able to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        cancelButtonText: "Annuler",
        confirmButtonText: "Oui",
      }).then((result) => {
        if (result.value) {
          // alert(id)
          this.deleteQuestion();
          // location.reload();
        }
      });
    },
    deleteQuestion() {
      Api.delete(`/games/rest/question/delete/${this.question.id}`)
        .then((response) => {
          console.log(response);
          Swal.fire("Deleted!", "Votre question a été supprimé avec succès", "success");
          location.reload();
        })
        .catch((error) => {
          this.process = false;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },
  },
  mounted() {
    console.log(this.question)
    this.questionQuiz.indication = this.question.indication
    this.questionQuiz.name = this.question.content
    this.questionQuiz.isMultiChoice = this.question.isMultiChoice
  },
};
</script>

<style>
.card-img {
  object-fit: cover;
  height: 200px;
  width: 100%;
  border-radius: 9px;
  margin-bottom: 20px;
}
</style>
