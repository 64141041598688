<script>

/**
 * Stat component -- specify the widget icon, title and value.
 */
export default {
  props: {
    title: {
      default: ""
    },
    value: {
      default: ""
    },
    icon: {
      default: ""
    },
    className: {
      default: ""
    },
  }
};
</script>

<template>
  <div :class="className">
    <!-- <Stat :icon="stat.icon" :title="stat.title" :value="stat.value" /> -->
    <div class="card mini-stats-wid c2play-border-primary">
      <div class="card-body">
        <div class="d-flex">
          <div class="flex-grow-1">
            <p class="text-muted fw-medium mb-2">{{ title }}</p>
            <h4 class="mb-0">{{ value }}</h4>
          </div>

          <div class=" avatar-sm align-self-center mini-stat-icon rounded-circle">
            <span class="c2play-primary avatar-title">
              <i :class="`${icon} font-size-30`"></i>
            </span>
          </div>
        </div>
      </div>
      <!-- end card-body -->
    </div>
  </div>
  <!-- end card -->
</template>